<template>
  <ReportTemplateEditor
    v-if="isLoaded"
    :is-existing-report="true"
    :report-template-parent="reportTemplate"
    @update-report="updateReportTemplate"
    @update-should-save="updateShouldSave"
  />
</template>

<script>
import ReportsApi from "@/api/ReportsApi";

export default {
  beforeRouteLeave(to, from, next) {
    if (this?.shouldSave) {
      let warningMessage =
        "רגע! שמנו לב שלא שמרתם את השינויים שעשיתם, רוצים לצאת בלי לשמור?";

      const answer = window.confirm(warningMessage);
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  data() {
    return {
      isLoaded: false,
      reportTemplate: null,
      shouldSave: false,
    };
  },
  async created() {
    await this.fetchReportTemplate();
    this.isLoaded = true;
  },
  methods: {
    updateShouldSave(val) {
      this.shouldSave = val;
    },
    async fetchReportTemplate() {
      let loader = this.$loading.show();
      const data = await ReportsApi.getReport(this.$route.params.id);
      this.reportTemplate = data;
      loader.hide();
    },
    async updateReportTemplate(reportTemplate) {
      let loader = this.$loading.show();

      try {
        await ReportsApi.updateReport(reportTemplate.id, {
          preference_id: reportTemplate.preference_id,
          stock_id: reportTemplate.stock_id,
          name: reportTemplate.name,
          title: reportTemplate.title,
          itemPaddingTop: reportTemplate.itemPaddingTop,
          itemPaddingBottom: reportTemplate.itemPaddingBottom,
          enableDefectStatus: reportTemplate.enableDefectStatus,
          enableDefectRecheckStatus: reportTemplate.enableDefectRecheckStatus,
          data: reportTemplate.data,
        });

        loader.hide();
        this.$toast.success("תבנית הדוח עודכנה בהצלחה");
      } catch (e) {

        loader.hide();
        this.$toast.error(
          this.$Utils.toastMessageFromResponse(
            e,
            "אירעה שגיאה בעת עדכון תבנית הדוח"
          )
        );
      }
    },
  },
};
</script>

<style scoped>
.v-list-item {
  transition: 0.2s;
}
</style>
